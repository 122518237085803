import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rowSelect } from '../../redux/ducks/rowSelect';
import { useHistory } from 'react-router-dom';
import { BsCheck } from "react-icons/bs";
import { getWeeklyScore } from "../../utils/TableHelper";

import PointsCell from './PointsCell';
import CalcCell from './CalcCell';


const Checkbox = (props) => {
    const { clickAction, selected } = props;

    const handleClick = () => {
        clickAction()
    }

    return (
        <td className="px-[5px]" onClick={() => handleClick()}>
            <div className='w-[20px] h-[20px] border-2 border-white rounded-sm'>
                {selected ? (
                    <div className="bg-white flex">
                        <BsCheck className="font-extrabold text-site-background"/>
                    </div> 
                ) : null
                }
            </div>
        </td>
    )
}


const Row = (props) => {
    const { row, scoring, sortColumn, teamFilter, selectedRows, useCompare, filterMatch, week, colorGenerator, allowRowSelect } = props;
    var { rk } = props;

    var total = 0;
    var games = 0;

    const dispatch = useDispatch();
    let history = useHistory();
    
    const customScoring = useSelector((state) => state.customScoring.customScoring);


    const year = Number(useSelector((state) => state.year.year));

    const rankStyling = {
        fontSize: '14px',
        padding: '0px 0px',
        paddingLeft: '0px',
        paddingRight: '5px',
        width: '50px',
        fontWeight: '600'
    }

    const nameCellStyling = {
        fontSize: '13px',
        padding: '10px 15px 10px 0px',
        marginLeft: '0px',
        fontWeight: '600',
        width: '120px'
    }

    const rowStyling = {
        'borderBottom': '1px solid #b7bdac',
        'cursor': 'pointer'
    }

    const isSelected = () => {
        return (row.name+row.position+row.team in selectedRows)
    }

    if (isSelected()) {
        rowStyling.backgroundColor = '#152238';
    }

    const numSelected = Object.keys(selectedRows).length;
    if (numSelected > 0 && !isSelected() && useCompare) {
        return (
            <tr/>
        );
    }

    const allowClickToPlayerView = () => {
        return  (year >= 2022)
    }

    const goToPlayerView = () => {
        if (allowClickToPlayerView()) {
            history.push(`/player/${row.playerId}`);
        }
    }

    const selectRow = () => {
        if (allowRowSelect) {
            const rowKey = row.name + row.position + row.team;
            dispatch(rowSelect(rowKey));
        }
    }

    const incrementGames = (obj) => {
        if (Object.keys(obj).length === 0 || obj.hasOwnProperty('bye')) {
          return 0;
        }
        return 1;
      }

    const addPointsCell = (weekObj, scoring, sortColumn, index, empty, colorGenerator) => {
        var setToGlow = false;

        let weeklyScore = (weekObj.bye !== true && Object.keys(weekObj).length > 0)
                ? getWeeklyScore(weekObj, scoring, customScoring)
                : 0
                
        // Sorting by multiple columns, the total an avg should reflect these columns only
        if (sortColumn.length > 1) {
            if (sortColumn.includes((index+1).toString())) {
                total += weeklyScore
                games += incrementGames(weekObj);
                setToGlow = true;
           }
        // If there is only only sort column, we want calculate the total and average normally
        } else {
            total += weeklyScore
            games += incrementGames(weekObj)
            if (sortColumn.includes((index+1).toString())) {
                setToGlow = true;
           }
        }

        const summaryKey = row.name + row.position + row.team + index;

        return (
            <PointsCell key={ index } value={ weekObj } scoring={ scoring } glow={ setToGlow } empty={empty} colorGenerator={colorGenerator} summaryKey={summaryKey} position={row.position}/>
        );
    }

    const getExtraCell = (weekLength, currentWeek, item, scoring, sortColumn, index) => {
        if (weekLength < currentWeek) {
            return addPointsCell(item, scoring, sortColumn, index, true);
        }
    }

    if (teamFilter !== row.team.toLowerCase() && teamFilter !== 'team' && !isSelected()) {
        return null;
    }

    if (filterMatch === false) {
        rk = '-'
    }

    return (
        <tr style={ rowStyling }>   
            <Checkbox clickAction={selectRow} selected={isSelected()}/>
            <td className="rank" style={rankStyling} > { rk } </td>
            <td className="namePosTeam" style={nameCellStyling}>
                <span className={(allowClickToPlayerView() ? "hover:underline hover:font-extrabold" : "")} onClick={() => goToPlayerView()}>{ `${row.name}`} </span>
                <br/> 
                {`(${row.position}, ${row.team})` } 
            </td>
                        
            {
            row.weeks.map((item, index) => (
                addPointsCell(item, scoring, sortColumn, index, false, colorGenerator)
            ))
            }
            { getExtraCell(row.weeks.length, week, 0, scoring, sortColumn, week-1) }

            <CalcCell className="Total" sortColumn={ sortColumn } value={ `${total}` } scoring={ scoring }/>
            <CalcCell className="Avg" sortColumn={ sortColumn } value={ `${total/games}` } scoring={ scoring }/>
        </tr>
    );
}

export default Row;