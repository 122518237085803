const SCORING_TYPE = 'scoringType';

export const scoringType = (scoring) => ({
    type: SCORING_TYPE,
    scoring: scoring
})

const initialState = {
    scoringType: 'ppr'
}

const scoring = (state = initialState, action) => {
    switch (action.type) {
        case SCORING_TYPE:
            return {...state, scoringType: action.scoring.value};
        default:
            return state;
    }
}

export default scoring;