const SUMMARY = 'summary';                        // Define the data type 

// This is our setter
// dispatch(year(2021));
export const setSummary = (summary) => ({      // Define our action
    type: SUMMARY,                                     // Data type and value(year)
    summary: summary                                        
})

// Define data format
const initialState = {                                      // Define initialState
    summary: ''
}

// Define reducer
// This part will be used to retrieve the state. 
// const year = useSelector((state) => state.setYear.year);
const summary = (state = initialState, action) => {
    switch(action.type) {
        case SUMMARY:
            return {...state, summary: action.summary};
        default:
            return state;
    }
}      

export default summary;