const COLOR_GENERATOR = 'colorGenerator';                        // Define the data type 

// This is our setter
// dispatch(year(2021));
export const setColorGenerator = (colorGenerator) => ({      // Define our action
    type: COLOR_GENERATOR,                                     // Data type and value(year)
    colorGenerator: colorGenerator                                        
})

// Define data format
const initialState = {                                      // Define initialState
    colorGenerator: ''
}

// Define reducer
// This part will be used to retrieve the state. 
// const year = useSelector((state) => state.setYear.year);
const colorGenerator = (state = initialState, action) => {
    switch(action.type) {
        case COLOR_GENERATOR:
            return {...state, colorGenerator: action.colorGenerator};
        default:
            return state;
    }
}      

export default colorGenerator;