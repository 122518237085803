// Return a number. Do not call unless you've validated it's a not bye week or a missing week
export const getWeeklyScore = (weekObj, scoring, customScoring) => {
    if (weekObj.bye === true) {
        throw new Error("TableHelper: getWeeklyScore can't be passed a weekObj that is a BYE");
    }

    if (Object.keys(weekObj).length === 0) {
        throw new Error("TableHelper: getWeeklyScore can't be passed an empty weekObj");
    }

    if (scoring === 'custom') {
        let numerator = 0;
        let denominator = 0;

        customScoring.numerator.forEach(scoring => {
            if (weekObj.hasOwnProperty(scoring.value)) {
                numerator += Number(weekObj[scoring.value]);
            }
        });

        customScoring.denominator.forEach( scoring  => {
            if (weekObj.hasOwnProperty(scoring.value)) {
                denominator += Number(weekObj[scoring.value]);
            }
        });

        // If numerator is empty, return 0
        if (numerator === 0) return 0;

        // If denominator is empty, treat it as 1
        if (denominator === 0) denominator = 1;

        return Number(numerator / denominator);
    } else {
        return Number(weekObj?.[scoring]);
    }
};