import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TablePPR from './TablePPR';
import { useDispatch, useSelector } from 'react-redux';
import { setUseCompare } from '../../redux/ducks/compare';
import { rowSelect } from '../../redux/ducks/rowSelect';
import { baseUrl, isProd } from '../../App';
import { CircularProgress } from '@material-ui/core';



let positionMap = {
    'QB': 'Quarterback',
    'WR': 'Wide Reciever',
    'RB': 'Running Back',
    'TE': 'Tight End'
}

let teamMap = {
    'ARI':	'Arizona Cardinals',
    'ATL':	'Atlanta Falcons',
    'BAL':	'Baltimore Ravens',
    'BUF':	'Buffalo Bills',
    'CAR':	'Carolina Panthers',
    'CHI':	'Chicago Bears',
    'CIN':	'Cincinnati Bengals',
    'CLE':	'Cleveland Browns',
    'DAL':	'Dallas Cowboys',
    'DEN':	'Denver Broncos',
    'DET':	'Detroit Lions',
    'GB':	'Green Bay Packers',
    'HOU':	'Houston Texans',
    'IND':	'Indianapolis Colts',
    'JAC':	'Jacksonville Jaguars',
    'KC':	'Kansas City Chiefs',
    'LV':	'Las Vegas Raiders',
    'LAC':	'Los Angeles Chargers',
    'LAR':	'Los Angeles Rams',
    'MIA':	'Miami Dolphins',
    'MIN':	'Minnesota Vikings',
    'NE':	'New England Patriots',
    'NO':	'New Orleans Saints',
    'NYG':	'New York Giants',
    'NYJ':	'New York Jets',
    'PHI':	'Philadelphia Eagles',
    'PIT':	'Pittsburgh Steelers',
    'SF':	'San Francisco 49ers',
    'SEA':	'Seattle Seahawks',
    'TB':	'Tampa Bay Buccaneers',
    'TEN':	'Tennessee Titans',
    'WAS':	'Washington Commanders'
}


const PlayerDemographicRow = (props) => {
    const { label1, value1, label2, value2 } = props

    return (
        <div className="flex space-x-[20px]">
            <div className="flex w-[290px] h-[25px] border-b-[1px]">
                <p className="w-full text-left font-light text-sm">{label1}</p>
                <div className="w-full flex justify-end">
                    <p className="font-bold">{value1}</p>
                </div>
            </div>
            
            <div className="flex w-[290px] h-[25px] border-b-[1px]">
                <p className="w-full text-left font-light text-sm">{label2}</p>
                <div className="w-full flex justify-end">
                    <p className="font-bold">{value2}</p>
                </div>
            </div>
        </div>
    );
}


const PlayerView = (props) => {
    const { playerId } = useParams();

    const dispatch = useDispatch();

    const [player, setPlayer] = useState({})
    const [didLoad, setDidLoad] = useState(false)

    const useCompare = useSelector((state) => state.useCompare.useCompare);

    useEffect(() => {
        dispatch(setUseCompare(true));
        dispatch(rowSelect(''));

        var url = '';
        if (isProd()) {
            url = baseUrl + `/api/player/${playerId}`
        } else {
            url = `http://localhost:3000/api/player/${playerId}`
        }

        axios.request({
            method: 'get',
            url: url,
        }).then(resp => {
            setPlayer(resp.data)
            setDidLoad(true)
        });
    }, [])

    useEffect(() => {
        dispatch(setUseCompare(true));
    }, [useCompare])


    useEffect(() => {
        console.log(rowSelect(player.shortName + player.position + player.team))
        dispatch(rowSelect(player.shortName + player.position + player.team));
    }, [player])
    

    return (
        <div className="flex justify-center flex-wrap">
            {didLoad ? (
                <div>
                    <div className="px-12 py-5 w-[1200px]">
                        <div className="flex">
                            <p className="font-extrabold text-3xl">{player.name}</p>
                        </div>
                        <div className="flex">
                            <p className="font-medium text-xl">{ `${positionMap[player.position]} • ${teamMap[player.team]} • #${player.number}` }</p>
                        </div>
                    </div>
                    
                    <div className="px-12 py-2 w-[1200px] mb-3">
                        <div className="w-[600px] px-5 py-4 space-y-2 border-2 rounded-md shadow-xl">
                            <PlayerDemographicRow label1="AGE" value1={player.age + " y.o."} label2="DRAFTED" value2={"Rd. " + player.draftRound + ", Pick " + player.pick}/>
                            <PlayerDemographicRow label1="BORN" value1={player.birthdate} label2={"DRAFT CLASS"} value2={player.draftYear}/>
                            <PlayerDemographicRow label1="HEIGHT" value1={player.height} label2={"YRS. EXP."} value2={player.experience + " yrs."}/>
                            <PlayerDemographicRow label1="WEIGHT" value1={player.weight} label2="COLLEGE" value2={player.college}/>
                        </div>
                    </div>
                </div>
            ) : (
                <CircularProgress/>
            )}

            <div className="w-screen border-[0.5px] mb-10 mt-5 mx-12"/>

            <TablePPR blockFiltersList={['team', 'switch', 'year', 'select']}/>
        </div>
    )
}

export default PlayerView;