const TEAM_FILTER = 'teamFilter';

export const teamFilter = (team) => ({
    type: TEAM_FILTER,
    team: team
})

const initialState = {
    teamFilter: 'team'
}

const team = (state = initialState, action) => {
    switch (action.type) {
        case TEAM_FILTER:
            return {...state, teamFilter: action.team.value};
        default:
            return state;
    }
}

export default team;