import React from 'react';

const CalcCell = (props) => {
    const { sortColumn, value, scoring } = props;

    const headerStyling = {
        fontSize: '15px',
        padding: '10px 15px',
        fontWeight: '600',
    }

    const parse = (value) => {
        return parseFloat(value).toFixed(1);
    };

    if (sortColumn.length > 1) {
        headerStyling['color'] = '#CCCC00';
    }

    return (
        <td style={headerStyling}>
            { parse(value) }             
        </td>
    );
}


export default CalcCell;