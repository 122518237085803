import React, { useState } from "react";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import CustomScoringDropdown from "./CustomScoringDropdown";
import { useSelector } from "react-redux";
import AddButton from './AddButton';



const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdditionSign = () => {
  return (
    <div className="mx-3 font-bold text-lg">
      +
    </div>
  )
}

const CustomMathModal = (props) => {
  const { isOpen, setIsOpen } = props;

  const customScoring = useSelector((state) => state.customScoring.customScoring);


  const renderFractionSide = (customScoring, sideStr) => {
    let fractionSide = customScoring[sideStr]

    let keys = Object.keys(fractionSide)
    if (keys.length === 0) {
      return (
        <div className="flex">
          <AddButton sideStr={sideStr} customScoring={customScoring}/>
        </div>
      )
    } else {
    return (
      <div className="flex">
        {keys.map(index => {
          return (
            <div className="flex">
              <CustomScoringDropdown key={`${sideStr}-${index}-${fractionSide[index].value}`} customScoring={customScoring} canDelete={false} sideStr={sideStr} index={index} defaultValue={fractionSide[index]}/>
              {(index < 2) ? (
                <AdditionSign/>
              ) : null}
            </div>
          )
        })}
        {(keys.length < 3) ? (
          <AddButton sideStr={sideStr} customScoring={customScoring}/>
        ) : null }
      </div>
      )
    }
  }

  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        className="rounded-lg"
      >
        <DialogContent className="bg-site-background">
          <div className=" w-[650px] h-[300px] p-[10px]">
            <div className="text-white font-bold">Create your own stats</div>
                <div className="flex mt-10">
                    <div className="text-white pb-[10px] text-4xl font-serif italic my-auto">f(x) =</div>
                    <div className="text-white my-auto ml-3">
                        <div className="mb-4 flex justify-center">
                          { renderFractionSide(customScoring, "numerator") }
                        </div>

                        <div className=" h-[0px] border-t-[3px] w-[500px]"/>

                        <div className="mt-5 flex justify-center">
                          { renderFractionSide(customScoring, "denominator") }
                        </div>
                    </div>
                </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CustomMathModal