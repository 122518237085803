const AUTHENTICATE = 'authenticate';                        // Define the data type 

// This is our setter
// dispatch(isAuthenticated(true));
export const setAuthenticate = (isAuthenticated) => ({      // Define our action
    type: AUTHENTICATE,                                     // Data type and value(authenicated)
    isAuthenticated: isAuthenticated                                        
})

// Define data format
const initialState = {                                      // Define initialState
    isAuthenticated: false
}

// Define reducer
// This part will be used to retrieve the state. 
// const isAuthenticated = useSelector((state) => state.isAuthenticated.isAuthenticated);
const isAuthenticated = (state = initialState, action) => {
    switch(action.type) {
        case AUTHENTICATE:
            return {...state, isAuthenticated: action.isAuthenticated};
        default:
            return state;
    }
}      

export default isAuthenticated;