import React from 'react'

import { useSelector } from 'react-redux';



const BoomBustTable = (props) => {
    const { data, isLoading, isBoom } = props; 

    const colorGenerator = useSelector((state) => state.colorGenerator.colorGenerator);

    const getTitle = () => {
        if (isBoom) {
            return "Booms"
        } else {
            return "Busts"
        }
    }

    //newStyling['borderBottom'] = '3px solid ' + colorGenerator[Math.round(Number(formatValue))]

    const getDiffColor = (value, isBoom) => {
        if (isBoom) {
            return colorGenerator[Math.round(Number(value) * 1.6)]
        } else {
            return colorGenerator[Math.round(Number(value) * 1.6 + 20)]
        }
    }

    const getDiffDisplayValue = (value) => {
        if (value > 0) {
            return "+" + value
        } else {
            return value
        }
    }

    const getTableBody = (data, isBoom) => {
        return data.map((row, index) => {
            const diffColor = getDiffColor(row.difference, isBoom)
            const actualColor = colorGenerator[Math.round(Number(row.actual))]
            const projColor = colorGenerator[Math.round(Number(row.projected))]

            return (
                <tr key={index} className="text-[13px] font-semibold" >
                    <td className="text-left pb-1 font-bold"> {index+1 + ".  " + row.name} <span className="font-semibold">({row.position}, {row.team}) </span></td>
                    <td className="font-semibold w-[35px]" > 
                        <div className=" mx-6 w-10" style={{'borderBottomColor': diffColor, borderBottomWidth: '3px'}}>
                            {getDiffDisplayValue(row.difference)} 
                        </div>
                    </td>
                    {/*
                    <td className="font-semibold">
                        <div className=" mx-3 w-10" style={{'borderBottomColor': projColor, borderBottomWidth: '3px'}}>
                            {row.projected} 
                        </div>
                    </td>
                    */}
                    <td className="font-semibold"> 
                        <div className=" mx-6 w-10" style={{'borderBottomColor': actualColor, borderBottomWidth: '3px'}}>
                            {row.actual}
                        </div>
                    </td>
                </tr>
            )
        });
    }

    return (
        <div className="pl-2 pt-1 pb-2">
            {(!isLoading) ? (
                <table>
                    {/*<div className="mb-2 text-base font-semibold"> {getTitle()} </div>*/}
                    <tr className={"font-extrabold" + " text-gray-100"}>
                        <td className="">Name</td>
                        <td className="">Vs Proj</td>
                        {/*<td className="">Proj</td>*/}
                        <td className="">Actual</td>
                    </tr>
                    {getTableBody(data, isBoom)}
                </table>
            ) : (null)}
        </div>
    )
} 

export default BoomBustTable;