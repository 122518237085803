import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortCol } from '../../redux/ducks/sort';

const Header = (props) => {
    const dispatch = useDispatch();
    const { headerRow } = props;

    const sortColumn = useSelector((state) => state.sortCol.sortCol);

    const rowStyling = {
        'borderBottom': '1px solid #b7bdac'
    }

    const headerStyling = {
        fontSize: '14px',
        paddingBottom: '5px',
        paddingTop: '10px',
        fontWeight: '600',
    }



    const updateSortCol = (props) => {
        dispatch(sortCol(props.target.innerText));
    }

    return (
        <thead>
            <tr style = { rowStyling } >
                <th className=" bg-site-background"/>
                <th> </th>
                {headerRow
                    .filter((item) => {
                        return (item !== 'Pos' && item !== 'Team')
                    })
                    .map((item) => {
                        if (item === 'Name') {
                            return (
                                <th key={item} style={ headerStyling } >
                                    { item }
                                </th>
                            );
                        } else {
                            let myStyle = Object.assign({}, headerStyling, {'cursor': 'pointer'});

                            if (sortColumn.includes(item) && item !== 'Total' && item !== 'Avg') {
                                // uncomment to enable header highlighting
                                myStyle = Object.assign({}, myStyle, {'background': '#666600'})
                            }

                            return (
                                <th key={item} style={ myStyle } onClick={ updateSortCol } >
                                    { item }
                                </th>
                            );
                        }
                    })
                }
            </tr>
        </thead>
    )
}

export default Header;
