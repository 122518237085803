const YEAR = 'year';                        // Define the data type 

// This is our setter
// dispatch(year(2021));
export const setYear = (year) => ({      // Define our action
    type: YEAR,                                     // Data type and value(year)
    year: year                                        
})

// Define data format
const initialState = {                                      // Define initialState
    year: 2023
}

// Define reducer
// This part will be used to retrieve the state. 
// const year = useSelector((state) => state.setYear.year);
const year = (state = initialState, action) => {
    switch(action.type) {
        case YEAR:
            return {...state, year: action.year};
        default:
            return state;
    }
}      

export default year;