import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './stylesheets/CreateAccount.css';
import axios from 'axios';
import { setAuthenticate } from '../../redux/ducks/authenticate';
import EmailSent from './EmailSent';
import { baseUrl } from '../../App';

const Login = (props) => {
    const dispatch = useDispatch();

    const { showModalLogin, showModalSignUp, showModalForgotPassword } = props;

    const [verified, setVerified] = useState(true);
    const [errors, setErrors] = useState(false);
    const [verificationResent, setVerificationResent] = useState(false);

    const [form, setForm] = useState({
        username: '',
        password: ''
    });

    const signUp = () => {
        showModalLogin(false);
        showModalSignUp(true);
    };

    const forgotPassword = () => {
        showModalLogin(false);
        showModalForgotPassword(true);
    }

    const resendVerificationLink = (e) => {
        axios.request({
            method: 'post',
            url: baseUrl + '/api/user/resend',
            data: { username: form.username, baseUrl: baseUrl }
        }).then(resp => {
            setVerificationResent(true);
        }).catch(error => {});
    } 


    const submitLogin = (e) => {
        e.preventDefault();
        axios.request({
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            url: baseUrl + '/api/user/login',
            data: form
        }).then(resp => {
            dispatch(setAuthenticate(true));
            showModalLogin(false);
        }).catch(error=> {
            if (408 === error.response.status) {
                setVerified(false);
            }
            setErrors(true);
        });
    }


    return (
        <div>
            { verified ? (
            <div class="login">
                <div class="signupTitle">
                    <label > Login </label>
                </div>
                <form>
                    <div class="emailLabel formLabel">
                        <label> Email </label>
                    </div>

                    <div class="emailInput">
                        <input type="email" onChange={e => setForm({...form, username: e.target.value})} />
                    </div>


                    <div class="passwordLabel  formLabel">
                        <label> Password </label>
                    </div>

                    <div class="passwordInput">
                        <input type="password" onChange={e =>  setForm({...form, password: e.target.value})} />
                    </div>

                    {
                    // Add errors for login
                    errors ? (
                        <div>
                            <label className="errorText"> The email or password is incorrect </label>
                        </div>
                    ) : null
                    }
                    
                    <div> 
                        <button onClick={submitLogin}> Submit </button>
                    </div>

                    <div class="createAccountMsg" onClick={signUp}>
                        <label > Don't have an account? Sign-up</label>
                    </div>

                    <div className="forgotPassword" onClick={forgotPassword}>
                        <label > Forgot your password? </label>
                    </div>
                </form>
            </div>
        ) : (
            <div>
                { !verificationResent ? (
                    <div className="login">
                        <div>
                            Check your email to verify your account!
                        </div>
                        <div className="createAccountMsg centerText" onClick={ resendVerificationLink }>
                            Can't find your verification email?
                        </div>
                    </div>
                ) : (
                    <EmailSent />
                )} 
            </div>
        )}
        </div>

    );
}

export default Login;