import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import '../stylesheets/ScoringDropdown.css'
import { useDispatch } from 'react-redux';
import { setCustomScoring } from '../../../redux/ducks/customScoring';
import { AiOutlineClose } from "react-icons/ai";

const options = [
    {
        type: 'group', 
        name: 'SCORING',
        items: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'ppr',
                label: 'PPR'
            },
            {
                value: 'half',
                label: 'HALF'
            },
            {
                value: 'std',
                label: 'STD'
            }
        ]
    },
    {
        type: 'group', 
        name: 'RUSHING',
        items: [
            {
                value: 'rushAtts',
                label: 'Attempts'
            },
            {
                value: 'rushYards',
                label: 'Yards'
            },
            {
                value: 'rushTds',
                label: 'TDs'
            }
        ]
    },
    {
        type: 'group', 
        name: 'RECEIVING',
        items: [
            {
                value: 'recTar',
                label: 'Targets'
            },
            {
                value: 'rec',
                label: 'Receptions'
            },
            {
                value: 'recYards',
                label: 'Yards'
            },
            {
                value: 'recTds',
                label: 'TDs'
            }
        ]
    },
    {
        type: 'group', 
        name: 'PASSING',
        items: [
            {
                value: 'passAtts',
                label: 'Attempts'
            },
            {
                value: 'passComp',
                label: 'Completions'
            },
            {
                value: 'passYards',
                label: 'Yards'
            },
            {
                value: 'passTds',
                label: 'TDs'
            },
            {
                value: 'int',
                label: 'Interceptions'
            }
        ]
    },
    {
        type: 'group', 
        name: 'MISC',
        items: [
            {
                value: 'fums',
                label: 'FUMBLES'
            },
            {
                value: 'twoPt',
                label: '2-PT'
            }
        ]
    }
];

const groupLabel = {
    'none': 'UNUSED',
    'ppr': 'SCORING',
    'half': 'SCORING',
    'std': 'SCORING',
    'rushAtts': 'RUSHING',
    'rushYards': 'RUSHING',
    'rushTds': 'RUSHING',
    'recTar': 'RECEIVING',
    'rec': 'RECEIVING',
    'recYards': 'RECEIVING',
    'recTds': 'RECEIVING',
    'passAtts': 'PASSING',
    'passComp': 'PASSING',
    'passYards': 'PASSING',
    'passTds': 'PASSING',
    'int': 'PASSING',
    'fums': 'MISC',
    'twoPt': 'MISC'
}


const CustomScoringDropdown = (props) => {
    const { defaultValue, sideStr, index, canDelete, customScoring } = props;

    const dispatch = useDispatch();

    const [value, setValue] = useState(defaultValue)

    React.useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]); 

    const handleChange = (selected) => {
        setValue(selected)
        calcCustomScoring(selected)
    }

    const calcCustomScoring = (selected) => {
        let updatedScoring = { ...customScoring };
    
        if (sideStr === 'numerator') {
            let newNumerator = [...updatedScoring.numerator];
            newNumerator[index] = selected;
            updatedScoring.numerator = newNumerator;
        } else {
            let newDenominator = [...updatedScoring.denominator];
            newDenominator[index] = selected;
            updatedScoring.denominator = newDenominator;
        }
    
        dispatch(setCustomScoring(updatedScoring));
    };

    const deleteSelf = () => {
        let updatedScoring;
    
        if (sideStr === 'numerator') {
            let newNumerator = [...customScoring.numerator];
            newNumerator.splice(index, 1);
            updatedScoring = {
                ...customScoring,
                numerator: newNumerator,
            };
        } else {
            let newDenominator = [...customScoring.denominator];
            newDenominator.splice(index, 1);
            updatedScoring = {
                ...customScoring,
                denominator: newDenominator,
            };
        }

        dispatch(setCustomScoring(updatedScoring));
    }
    
    return (
        <div className="relative">
            <div onClick={() => {deleteSelf()}} className="absolute w-[14px] h-[14px] text-site-background cursor-pointer hover:text-white hover:bg-red-600 hover:border-red-600 top-[-6px] right-[-5px] p-[1px] z-10 bg-white border-[1px] rounded-3xl flex justify-center">
                <AiOutlineClose size={10}/>
            </div>
            <div className=" absolute top-[-7px] left-[4px] z-10 mb-5 text-[10px] px-1 bg-site-background">{groupLabel[value.value]}</div>
            <Dropdown className="CustomDropdown" options={options} onChange={(e) => handleChange(e)} value={value} />
        </div>
    )
      
      
}

export default CustomScoringDropdown;