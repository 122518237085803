import React, { useState, useEffect } from 'react';
import BoomBustTable from './BoomBustTable';
import { baseUrl, isProd } from '../../../App';
import axios from 'axios';


const BoomBustContainer = () => {
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {        
        var url = '';
        if (isProd()) {
            url = baseUrl + `/api/boomAndBust/widget`
        } else {
            url = `http://localhost:3000/api/boomAndBust/widget`
        }

        axios.request({
          method: 'get',
          url: url
        }).then(resp => {
            setData(resp.data)
            setIsLoading(false)
        })
    }, []);

    return (    
        <div className="relative flex mt-1">
            <div className='flex border-[2px] border-gray-500 rounded-md mr-12'>
                <div className='w-24 text-[22px] font-bold pt-3 border-r-[1px] border-gray-500 mr-3'>
                    Booms <span className="text-[14px]">Week {data.currentWeek} (PPR)</span>
                </div>
                <div>
                    <BoomBustTable data={data.boom} isLoading={isLoading} isBoom={true}/>
                </div>
            </div>

            <div className='flex border-[2px] border-gray-500 rounded-md'>
                <div className='w-24 text-[22px] font-bold pt-3 border-r-[1px] border-gray-500 mr-3'>
                    Busts <span className="text-[14px]">Week {data.currentWeek} (PPR)</span>
                </div>
                <div>
                    <BoomBustTable data={data.bust} isLoading={isLoading} isBoom={false}/>
                </div>
            </div>
        </div>

    )
}

export default BoomBustContainer;