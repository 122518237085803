import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import './stylesheets/ScoringDropdown.css'
import { useDispatch } from 'react-redux';
import { scoringType } from '../../redux/ducks/scoring';
import CustomMathModal from './CustomFunction/CustomMathModal'


const options = [
    {
        type: 'group', 
        name: 'SCORING',
        items: [
            {
                value: 'custom',
                label: 'CUSTOM'
            },
            {
                value: 'ppr',
                label: 'PPR'
            },
            {
                value: 'half',
                label: 'HALF'
            },
            {
                value: 'std',
                label: 'STD'
            }
        ]
    },
    {
        type: 'group', 
        name: 'RUSHING',
        items: [
            {
                value: 'rushAtts',
                label: 'Attempts'
            },
            {
                value: 'rushYards',
                label: 'Yards'
            },
            {
                value: 'rushTds',
                label: 'TDs'
            }
        ]
    },
    {
        type: 'group', 
        name: 'RECEIVING',
        items: [
            {
                value: 'recTar',
                label: 'Targets'
            },
            {
                value: 'rec',
                label: 'Receptions'
            },
            {
                value: 'recYards',
                label: 'Yards'
            },
            {
                value: 'recTds',
                label: 'TDs'
            }
        ]
    },
    {
        type: 'group', 
        name: 'PASSING',
        items: [
            {
                value: 'passAtts',
                label: 'Attempts'
            },
            {
                value: 'passComp',
                label: 'Completions'
            },
            {
                value: 'passYards',
                label: 'Yards'
            },
            {
                value: 'passTds',
                label: 'TDs'
            },
            {
                value: 'int',
                label: 'Interceptions'
            }
        ]
    },
    {
        type: 'group', 
        name: 'MISC',
        items: [
            {
                value: 'fums',
                label: 'FUMBLES'
            },
            {
                value: 'twoPt',
                label: '2-PT'
            }
        ]
    }
];

const groupLabel = {
    'custom': 'SCORING',
    'ppr': 'SCORING',
    'half': 'SCORING',
    'std': 'SCORING',
    'rushAtts': 'RUSHING',
    'rushYards': 'RUSHING',
    'rushTds': 'RUSHING',
    'recTar': 'RECEIVING',
    'rec': 'RECEIVING',
    'recYards': 'RECEIVING',
    'recTds': 'RECEIVING',
    'passAtts': 'PASSING',
    'passComp': 'PASSING',
    'passYards': 'PASSING',
    'passTds': 'PASSING',
    'int': 'PASSING',
    'fums': 'MISC',
    'twoPt': 'MISC'
}

const ScoringDropdown = (props) => {
    const dispatch = useDispatch();

    const [openMathDialog, setOpenMathDialog] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState('SCORING')

    const handleChange = (selected) => {
        dispatch(scoringType(selected));
        setSelectedGroup(groupLabel[selected.value])
        if (selected.value === 'custom') {
            console.log('open sesame')
            setOpenMathDialog(true)
        }
    }

    const defaultOption = {
        value: 'ppr',
        label: 'PPR'
    }

    return (
        <div className="relative">
            <div className=" absolute top-[-7px] left-[18px] z-10 mb-5 text-[10px] px-1 bg-site-background">{selectedGroup}</div>
            <div className="dropdownContainer">
                <Dropdown options={options} onChange={(e) => handleChange(e)} onChangevalue={defaultOption} placeholder="PPR" />
                <CustomMathModal isOpen={openMathDialog} setIsOpen={setOpenMathDialog}/>
            </div>
        </div>
    )
      
      
}

export default ScoringDropdown;