const ROW_SELECT = 'rowSelect';

export const rowSelect = (row) => ({
    type: ROW_SELECT,
    row: row
});

const initialState = {
    rowSelect: {}
}

const select = (state = initialState, action) => {
    switch (action.type) {
        case ROW_SELECT:
            return getRows(state, action.row);
        default: 
            return state;
    }
}

const getRows = (state, row) => {
    var rows = state.rowSelect;

    if (row === '') {
        return {...state, rowSelect: {}}
    }

    if (rows[row]) {
        delete rows[row];
    } else {
        rows[row] = row;
    }

    return {...state, rowSelect: {...rows}};
}

export default select;