import React from 'react';
import Modal from 'react-modal';
import CreateAccount from './CreateAccount';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setAuthenticate } from '../../redux/ducks/authenticate';
import { baseUrl } from '../../App';
import logo from './logo-color-transparent.png';
import { FaTimes } from 'react-icons/fa';

import './stylesheets/TopBar.css'


const TopBar = () => {
    const history = useHistory();
    const dispatch = useDispatch();


    const [modalIsOpenSignUp, setIsOpenSignUp] = React.useState(false);
    const [modalIsOpenLogin, setIsOpenLogin] = React.useState(false);
    const [modalIsOpenForgotPassword, setIsOpenForgotPassword] = React.useState(false);
    const [alertIsOpen, setAlertIsOpen] = React.useState(false);

    const home = () => {
        history.push('/');
    }
    const toggleModalSignUp = () => {
      setIsOpenSignUp(!modalIsOpenSignUp);
    }

    const toggleModalLogin = () => {
        setIsOpenLogin(!modalIsOpenLogin);
    }

    const toggleModalForgotPassword = () => {
        setIsOpenForgotPassword(!modalIsOpenForgotPassword);
    }

    const logOut = () => {
        axios.defaults.withCredentials = true;
        axios.request({
            method: 'get',
            url: baseUrl + '/api/user/logout'
        }).then(resp => {
            dispatch(setAuthenticate(false));
        }).catch(e => {

        });
    }

    const isAuthenticated = useSelector((state) => state.isAuthenticated.isAuthenticated);
    
    return (
        <div>
            {alertIsOpen ? (
                <div className="bg-blue-800 h-6 mb-2 flex items-center justify-between">
                    <FaTimes 
                        className="cursor-pointer px-2 py-1 text-white"
                        onClick={() => setAlertIsOpen(false)}
                        size="1.6em"
                    />
                    <span className="flex-1 text-center">
                        <span className="font-bold">New: </span>Select CUSTOM in the scoring dropdown to combine stats (e.g., Receiving Yards + Rushing Yards)
                    </span>
                </div>
            ) : null}

            <div className="flex justify-center">
                <div className="flex-box space-x-96">
                    <div onClick={home} className="cursor-pointer">
                        <img src={logo} alt="Description of Image" className=" h-16" />
                    </div>
                    
                    <div className=""> 
                        <form action="https://www.buymeacoffee.com/stats4fantasy">
                            <button className="buyBeer" type="submit" value="">&#127866; Buy me a beer</button>
                        </form>
                    </div>

                    {isAuthenticated ? (
                        <div> 
                            <div className="signInUp">
                                <button onClick={logOut}> LOG OUT </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="signInUp">
                                <button onClick={toggleModalSignUp}> SIGN UP </button>
                                <button onClick={toggleModalLogin}> SIGN IN </button>
                            </div>
                            <Modal 
                                className="modal"
                                isOpen={modalIsOpenSignUp}
                                onRequestClose={toggleModalSignUp}
                            >
                                <CreateAccount showModalLogin={ setIsOpenLogin } showModalSignUp={ setIsOpenSignUp }/>
                            </Modal>
                            <Modal 
                                className="modal"
                                isOpen={modalIsOpenLogin}
                                onRequestClose={toggleModalLogin}
                            >
                                <Login showModalLogin={ setIsOpenLogin } showModalSignUp={ setIsOpenSignUp } showModalForgotPassword={ setIsOpenForgotPassword } />
                            </Modal>
                            <Modal 
                                className="modal"
                                isOpen={modalIsOpenForgotPassword}
                                onRequestClose={toggleModalForgotPassword}
                            >
                                <ForgotPassword showModalLogin={ setIsOpenLogin } showModalForgotPassword={ setIsOpenForgotPassword }/>
                            </Modal>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}


export default TopBar;