import React from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import './stylesheets/CreateAccount.css';


const EmailSent = () => {
    return (
        <div className="login"> 
            <div>
                Check your email to verify your account!
            </div>
            <div style={{textAlign: 'center', marginTop: '40px'}}>
                <DoneAllIcon style={{ color: 'green', height: '40px', width: '40px'}}/>
            </div>
        </div> 
    );
}

export default EmailSent;