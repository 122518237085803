const USE_COMPARE = 'useCompare';

export const setUseCompare = (useCompare) => ({
    type: USE_COMPARE,
    useCompare: useCompare
});

const initialState = {
    useCompare: false
}

const compare = (state = initialState, action) => {
    switch (action.type) {
        case USE_COMPARE:
            return {...state, useCompare: action.useCompare};
        default: 
            return state;
    }
}

export default compare;