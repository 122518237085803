import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from 'react-redux'; 
import { setUseCompare } from '../../redux/ducks/compare';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#FFFFFF',
    '&$checked': {
      color: '#FFFFFF',
    },
    '&$checked + $track': {
      backgroundColor: '#FFFFFF',
    },
  },
  checked: {},
  track: {},
})(Switch);


export default function CustomizedSwitches() {
  const dispatch = useDispatch();
  var useCompare = useSelector((state) => state.useCompare.useCompare);
  const rowSelect = useSelector((state) => state.rowSelect.rowSelect);

  const numSelected = Object.keys(rowSelect).length
  if (numSelected === 0) {
    dispatch(setUseCompare(false));
  }

  const handleChange = (event) => {
    dispatch(setUseCompare(event.target.checked));
  };

  return (
    <div>
        <PurpleSwitch checked={useCompare} onChange={handleChange} size="small"/>
        <React.Fragment>
            Compare {'(' + numSelected + ')'}
        </React.Fragment>
    </div>
  );
}