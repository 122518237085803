import React from 'react';
import Dropdown from 'react-dropdown';
import { useDispatch } from 'react-redux';
import './stylesheets/PositionDropdown.css'
import { posFilter } from '../../redux/ducks/filter';

const PositionDropdown = () => {
    const dispatch = useDispatch();

    const options = [
        {
            value: 'pos',
            label: 'POS'
        },
        {
            value: 'qb',
            label: "QB"
        },
        {
            value: 'rb',
            label: "RB"
        },
        {
            value: 'wr',
            label: "WR"
        },
        {
            value: 'flex',
            label: 'FLEX'
        },
        {
            value: 'te',
            label: "TE"
        },

    ];

    const handleChange = (selected) => {
        dispatch(posFilter(selected));
    }

    const defaultOption = options[4];

    return (
        <div className="dropdownContainer">
            <Dropdown options={options} onChange={(e) => handleChange(e)} value={defaultOption} />
        </div>
    )
      
      
}

export default PositionDropdown;