
import './App.css';
import TablePPR from './components/TablePPR/TablePPR';
import TopBar from './components/TablePPR/TopBar';
import AccountVerification from './components/TablePPR/AccountVerification';
import ResetPassword from './components/TablePPR/ResetPassword';
import PlayerView from './components/TablePPR/PlayerView';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import { setAuthenticate } from './redux/ducks/authenticate';
import { useDispatch } from 'react-redux';


import { Switch, Route, useHistory } from "react-router-dom";


ReactGA.initialize('UA-193328229-1', {
  name: 'pageHit'
});

export const getPosition = (string, subString, index) => {
  return string.split(subString, index).join(subString).length;
}

/**
 * Create and initialize the baseUrl
 * ie. localhost:3001 or stats4fantasy.com
 */
export const baseUrl = window.location.href.substring(0, getPosition(window.location.href, '/', 3));


export const isProd = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return false;
  } else {
    return true;
  }
}



function App() {
  const dispatch = useDispatch();

  document.title = 'Weekly Fantasy Stats';

  ReactGA.pageview(window.location.pathname + window.location.search);  

  useEffect(() => {
    axios.request({
      method: 'get',
      url: baseUrl + '/api/user/isAuthenticated',
    }).then(resp => {
      dispatch(setAuthenticate(true));
    }).catch(e => {
      dispatch(setAuthenticate(false));
    });
  }, []);


  return (
    <div className="App h-screen bg-site-background">
        <header className="App-header">
          <meta name="viewport" content="width=1350" />
              <TopBar />
              <Switch>
                <Route exact path='/reset/*' render={() => <ResetPassword />} />
                <Route exact path='/verify/*' render={() => <AccountVerification/>} />
                <Route exact path='/player/:playerId' render={() => <PlayerView />} />
                <Route exact path='/*' render={() => <TablePPR/>} />
              </Switch>
        </header>
    </div>
  );
}

export default App;
