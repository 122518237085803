import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { baseUrl, isProd } from '../../App';

import 'react-dropdown/style.css'; 
import PositionDropdown from './PositionDropdown';
import ScoringDropdown from './ScoringDropdown';
import TeamDropdown from './TeamDropdown';
import YearDropdown from './YearDropdown';
import './stylesheets/TablePPR.css';
import Header from './Header';
import Body from './Body';
import CompareSwitch from './CompareSwitch';
import BoomBustContainer from './Projections/BoomBustContainer';

import axios from 'axios';
import { setTable } from '../../redux/ducks/table';
import { scoringType } from '../../redux/ducks/scoring';
import { setUseCompare } from '../../redux/ducks/compare';
import { teamFilter } from '../../redux/ducks/team';
import { setYear } from '../../redux/ducks/year';
import { posFilter } from '../../redux/ducks/filter';
import { rowSelect } from '../../redux/ducks/rowSelect';
import { setDidLoad } from '../../redux/ducks/loading';
import { useSelector, useDispatch } from 'react-redux';
import { currentYear } from './YearDropdown'
import { sortCol } from '../../redux/ducks/sort';




/*
TablePPR - Holds the dropdowns, table header, and table body
    PositionDropdown - Just a dropdown with the positions
    Header - Handles the table header
    Body - Holds all filtering and sorting
        Row - Handles selected rows & non-points player info (rank, name, team, position)
            PointsCell - Handles the weekly points. Column sort highlighting, underline colors, and the stat breakdown on click
            CalcCell - Handles Total and Average. Column sort highlight, and stat breakdown 

*/

const TablePPR = (props) => {

    const {blockFiltersList, blockRowSelect} = props;

    const tableStyling = {
        borderCollapse: 'collapse',
    }

    const dispatch = useDispatch();

    const table = useSelector((state) => state.table.table);
    const year = useSelector((state) => state.year.year);
    const didLoad = useSelector((state) => state.didLoad.didLoad);
    

    useEffect(() => {
        var url = '';
        if (isProd()) {
            url = baseUrl + `/api/gameLog?year=${year}`
        } else {
            url = `http://localhost:3000/api/gameLog?year=${year}`
        }

        axios.request({
          method: 'get',
          url: url
        }).then(resp => {
            dispatch(setTable(resp.data));
            dispatch(setDidLoad(true));
        }).catch(e => {
            dispatch(setTable({}));
            dispatch(setDidLoad(true));
        });
    }, []);

    useEffect(() => {
        dispatch(setUseCompare(false));
        dispatch(rowSelect(''));
        dispatch(scoringType({ value: "ppr", label: "PPR" }));
        dispatch(teamFilter({label: 'TEAM', value: 'team'}));
        dispatch(setYear(currentYear));
        dispatch(posFilter({value: 'flex', label: 'FLEX'}));
        dispatch(sortCol('Total'));
    }, [])

    useEffect(() => {
        dispatch(setUseCompare(false));
        dispatch(rowSelect(''));
        dispatch(scoringType({ value: "ppr", label: "PPR" }));
        dispatch(teamFilter({label: 'TEAM', value: 'team'}));
        dispatch(posFilter({value: 'flex', label: 'FLEX'}));
        dispatch(sortCol('Total'));
    }, [year])

    const setCurrentWeekRedux = () => {
        
    }

    const isFilter = (filter) => {
        return (blockFiltersList === undefined || 
            (blockFiltersList && !blockFiltersList.includes(filter)));
    };

    const getFilters = (blockFiltersList) => {
        return (
            <div className="controls">
                {(isFilter("year")) ? (
                    <div className="dropdown">
                        <YearDropdown/>
                    </div>
                ) : (<div/>)}

                {(isFilter('scroring')) ? (
                    <div className="dropdown">
                        <ScoringDropdown/>
                    </div>
                ) : (<div/>)}

                {(isFilter('position')) ? (
                    <div className="dropdown">
                        <PositionDropdown/>
                    </div>
                ) : (<div/>)}

                {(isFilter('team')) ? (
                    <div className="dropdown">
                        <TeamDropdown/>
                    </div>
                ) : (<div/>)}

                {(isFilter('switch')) ? (
                    <div className="compareSwitch">
                        <CompareSwitch/>
                    </div>
                ) : (<div/>)}
            </div>
        );
    }




    return (
        <div>
        <div className="flex justify-center mb-4">
            <BoomBustContainer/>
        </div>
        <div className="flex justify-center">
            <div>
                <div className="controls">
                    {getFilters(blockFiltersList)}
                </div>
            
                { didLoad ? (
                    <div className="tableFixHead">
                        <table style = { tableStyling } className="mx-4">
                            <Header headerRow={ table.header } className=""/>
                            <Body gameLog={ table.body } currentWeek={ table.week } allowRowSelect={ isFilter('select') }/>
                        </table> 
                    </div>
                ) : (
                    <div className="spinner">
                        <CircularProgress/>
                    </div>
                )}
                
                <p6> contact: stats4fantasy@stats4fantasy.com </p6>
            </div>  
        </div>  
        </div> 
    );
};

export default TablePPR;