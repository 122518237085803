import React from 'react';
import { setCustomScoring } from '../../../redux/ducks/customScoring';
import { useDispatch } from 'react-redux';

const AddButton = (props) => {
    const { sideStr, customScoring } = props;
    const dispatch = useDispatch();
  
    const addDropdown = (sideStr, customScoring) => {
        let updatedScoring;
      
        if (sideStr === 'numerator') {
          let newNumerator = [...customScoring.numerator];
          newNumerator.push({
            value: 'none',
            label: 'None'
          });
          updatedScoring = {
            ...customScoring,
            numerator: newNumerator,
          };
        } else {
          let newDenominator = [...customScoring.denominator];
          newDenominator.push({
            value: 'none',
            label: 'None'
          });
          updatedScoring = {
            ...customScoring,
            denominator: newDenominator,
          };
        }
      
        dispatch(setCustomScoring(updatedScoring));
      }
      
  
    return (
      <div className="bg-site-background border w-14 text-center border-white 
                      rounded h-[36px] flex items-center justify-center text-md" 
            onClick={() => addDropdown(sideStr, customScoring)}
      >
        Add
      </div>
    );
  }


  export default AddButton;