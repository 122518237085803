import React, { useState } from 'react';
import './stylesheets/CreateAccount.css';
import './stylesheets/TablePPR.css';
import axios from 'axios';
import { baseUrl } from '../../App';
import TopBar from './TopBar';
import { useHistory } from 'react-router-dom';
import DoneAllIcon from '@material-ui/icons/DoneAll';




const ResetPassword = () => {
    const history = useHistory();

    let resetToken = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);


    const [form, setForm] = useState({
        password: '',
        passwordMatch: ''
    });

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const submitResetPassword = (e) => {
        e.preventDefault();
        axios.request({
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            url: baseUrl + '/api/user/reset',
            data: Object.assign({}, form, {baseUrl: baseUrl, resetToken: resetToken})
        }).then(resp => {
            if (resp.data.errors) {
                setErrors(resp.data.errors);
            } else {
                setSuccess(true);
            }
            
        }).catch();
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
      

    const home = () => {
        history.push('/');
    }


    return (
        <div>
            <div className="page">
                {!success ? (
                <div class="login">
                    <div class="signupTitle">
                        <label > Reset Password </label>
                    </div>
                    <form>
                        <div class="passwordLabel  formLabel">
                            <label> Password </label>
                        </div>

                        {
                            // Add errors messages on bad password
                            errors.password ? (
                                <div>
                                    <label className="errorText"> { errors.password } </label>
                                </div>
                            ) : null
                        }

                        <div class="passwordInput">
                            <input type="password" onChange={e =>  setForm({...form, password: e.target.value})} />
                        </div>

                        <div class="confirmLabel  formLabel">
                            <label> Confirm Password </label>
                        </div>

                        {
                            // Add errors messages on bad passwordMatch
                            errors.passwordMatch ? (
                                <div>
                                    <label className="errorText"> { errors.passwordMatch } </label>
                                </div>
                            ) : null
                        }

                        <div class="confirmInput">
                            <input type="password" onChange={e =>  setForm({...form, passwordMatch: e.target.value})} />
                        </div>


                        {
                            // Add errors messages on bad passwordMatch
                            errors.resetToken ? (
                                <div>
                                    <label className="errorText"> { errors.resetToken } </label>
                                </div>
                            ) : null
                        }
                        
                        <div> 
                            <button onClick={submitResetPassword}> Submit </button>
                        </div>
                    </form>
                </div>
                ) : (
                    <div className="login">
                        <div>
                            Password Reset Successful!
                        </div>
                        <div style={{textAlign: 'center', marginTop: '40px'}}>
                            <DoneAllIcon style={{ color: 'green', height: '40px', width: '40px'}}/>
                        </div>

                        
                        <div class="createAccountMsg centerText" onClick={home}>
                            <label> &larr; Back to home screen </label>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );


}

export default ResetPassword;