import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { setSummary } from './../../redux/ducks/summary';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SummaryTooltip from './SummaryTooltip';
import { getWeeklyScore } from '../../utils/TableHelper'



const PointsCell = (props) => {
    const { value, scoring, glow, empty, colorGenerator, summaryKey, position } = props;

    const summary = useSelector((state) => state.summary.summary);
    const customScoring = useSelector((state) => state.customScoring.customScoring);

    const dispatch = useDispatch();

    var styling = {};
    const cellStyling = {
        fontSize: '14px',
        padding: '10px 15px',
        maxWidth: '125px',
        //background: 'beige'
    }

    const zeroCellStyling = {
        fontSize: '8px',
        padding: '10px 15px',
        maxWidth: '125px',
        //background: 'beige'
    }

    const updateSummary = (index) => {
        if (!value.bye && Object.keys(value).length > 0) {
            dispatch(setSummary(index));
        }
    } 

    const formatPointsCell = (weekObj, empty) => {
        if (empty) {
            return '';
        }

        if (value.bye) {
            cellStyling.fontSize = '9px'
            return 'BYE';
        } else if (Object.keys(value).length > 0) {
            let weeklyScore = getWeeklyScore(weekObj, scoring, customScoring)
            if (['ppr', 'half', 'std'].includes(scoring)) {
                return weeklyScore.toFixed(1);
            } else {
                return weeklyScore.toFixed(0);
            }
        } else {
            return '-'
        }
    }


    var formatValue = formatPointsCell(value, empty);
    if (formatValue === '0') {
        styling = zeroCellStyling;
    } else {
        styling = cellStyling;
    }

    if (glow) {
        styling['background'] = '#666600';
    }

    styling['padding'] = '12px'
    styling['minWidth'] = '58px'

    let newStyling = {}
    const useColor = ['rushTds', 'recTds', 'passTds', 'int', 'fums', 'twoPt', 'custom']
    if (!empty && formatValue !== '-' && formatValue !== 'BYE' && !useColor.includes(scoring)) {
        newStyling['borderBottom'] = '3px solid ' + colorGenerator[Math.round(Number(formatValue))]
        newStyling['paddingBottom'] = '2px'
    }

    const getPointsCell = () => {
        if (summary === summaryKey) {
            console.log()
            return (
                <ClickAwayListener onClickAway={() => updateSummary("", null)}>
                    <Tooltip title={<SummaryTooltip value={value} position={position}/>}
                        open={true}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListenera
                        arrow
                    >
                        <td style={styling} className="p-2">
                            <div style={newStyling}>{ formatValue } </div>
                        </td>
                    </Tooltip>
                </ClickAwayListener>
            );
        } else {
            return (
                <td style={styling} className="hover:underline hover:font-semibold" onClick={(e) => {updateSummary(summaryKey, e)}}>
                    <div style={newStyling} >{ formatValue } </div>
                </td>
            );
        }
    }
    

    return (
       getPointsCell()
    );
}

export default PointsCell;