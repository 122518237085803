import React from 'react';

const RushingSummary = (props) => {
    const { value } = props

    const hasRushAtts = (value['rushAtts'] !== undefined && value['rushAtts'] > 0)

    const getRushingSummary = () => {
        const hasRushYards = (value['rushYards'] !== undefined && value['rushYards'] > 0)
        const hasRushTds = (value['rushTds'] !== undefined && value['rushTds'] > 0)

        let rushingSummary = `${value['rushAtts']} Atts`
        if (hasRushYards) {
            rushingSummary += `, ${value['rushYards']} Yds`
        }
        if (hasRushTds) {
            rushingSummary += `, ${value['rushTds']} TD`
        }

        return rushingSummary
    }

    return (
        <>
            {hasRushAtts ? (
                <div>
                    <div className=" text-[10px]">RUSHING</div>
                    <div className=" text-[12px] mb-2">{getRushingSummary()}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

const ReceivingSummary = (props) => {
    const { value } = props

    const hasTargets = (value['recTar'] !== undefined && value['recTar'] > 0)
    const hasReceptions = (value['rec'] !== undefined && value['rec'] > 0)

    const getReceivingSummary = () => {
        const hasReceptionYards = (value['recYards'] !== undefined && value['recYards'] > 0)
        const hasReceptionTds = (value['recTds'] !== undefined && value['recTds'] > 0)

        let receivingSummary = ``

        if (hasTargets) {
           receivingSummary +=  `${value['recTar']} Tar`
           if (hasReceptions) {
               receivingSummary += `, `
           }
        }
        if (hasReceptions) {
            receivingSummary += `${value['rec']} Rec`
        }
        if (hasReceptionYards) {
            receivingSummary += `, ${value['recYards']} Yds`
        }
        if (hasReceptionTds) {
            receivingSummary += `, ${value['recTds']} TD`
        }

        return receivingSummary
    }

    return (
        <>
            {hasTargets || hasReceptions ? (
                <div>
                    <div className=" text-[10px]">RECEIVING</div>
                    <div className=" text-[12px] mb-2">{getReceivingSummary()}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

const PassingSummary = (props) => {
    const { value } = props

    const hasPassAtts = (value['passAtts'] !== undefined && value['passAtts'] > 0)

    const getPassingSummary = () => {
        const hasPassYards = (value['passYards'] !== undefined && value['passYards'] > 0)
        const hasPassTds = (value['passTds'] !== undefined && value['passTds'] > 0)
        const hasPassInt = (value['int'] !== undefined && value['int'] > 0)

        const completions = (value['passComp'] === undefined ? 0 : value['passComp'])

        let passingSummary = `${completions} / ${value['passAtts']}`
        if (hasPassYards) {
            passingSummary += `, ${value['passYards']} Yds`
        }
        if (hasPassTds) {
            passingSummary += `, ${value['passTds']} TD`
        }
        if (hasPassInt) {
            passingSummary += `, ${value['int']} Int`
        }

        return passingSummary
    }

    return (
        <>
            {hasPassAtts ? (
                <div>
                    <div className=" text-[10px]">PASSING</div>
                    <div className=" text-[12px] mb-2">{getPassingSummary()}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
} 

const SummaryTooltip = (props) => {
    const { value } = props;

    return (
        <div style={{
            backgroundColor: '#282c34',
            borderRadius: '5px',
            color: '#dfe7f5',
            marginLeft: '-3px',
            marginRight: '-4px',
            width: '200px',
            fontSize: '13px',
            padding: '5px',
            fontWeight: 'bold'
        }}>
            <PassingSummary value={value}/>
            <RushingSummary value={value}/>
            <ReceivingSummary value={value}/>
        </div>
    )
}

export default SummaryTooltip;