import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import Rainbow from 'rainbowvis.js';
import Row from './Row';
import { getWeeklyScore } from '../../utils/TableHelper'
import { setColorGenerator } from '../../redux/ducks/colorGenerator';



ReactGA.initialize('UA-193328229-1', {
    name: 'actionHit'
  });

const Body = (props) => {
    ReactGA.pageview(window.location.pathname + window.location.search);  

    var { gameLog, currentWeek, allowRowSelect } = props;

    const dispatch = useDispatch();

    const customScoring = useSelector((state) => state.customScoring.customScoring);
    const colorGeneratorRedux = useSelector((state) => state.colorGenerator.colorGenerator);



    // Filter logic
    const filter = (row, posFilter, rowSelect) => {
        const position = row.position.toLowerCase();

        if (rowSelect[row.name+row.position+row.team]) {
            return true;
        }

        return posFilterLogic(posFilter, position);
    };


    const posFilterLogic = (posFilter, position) => {
        if (posFilter === 'pos') {
            return true;
        } else if (posFilter === 'flex') {
            return (position === 'rb' || position === 'wr' || position === 'te');
        } else {
            return (position === posFilter);
        }
    } 

//        const week = obj.weeks[weekIndex];

    // Sort by total
    const getTotalScore = (obj, scoring) => {
        return obj.weeks.reduce((total, weekObj) => {
            const weeklyScore = (weekObj.bye !== true && Object.keys(weekObj).length > 0)
                ? getWeeklyScore(weekObj, scoring, customScoring)
                : 0
            return weeklyScore ? total + Number(weeklyScore) : total;
        }, 0);
    };
    
    // Sort by average
    const getAverageScore = (obj, scoring) => {
        const totalScore = getTotalScore(obj, scoring);
        return totalScore / obj.games;
    };
    
    // Sort by certain columns
    const getCustomScore = (obj, sortCol, scoring) => {
        return sortCol.reduce((total, column) => {
            const weekIndex = parseInt(column) - 1;
            const weekObj = obj.weeks[weekIndex];
            const weeklyScore = (weekObj.bye !== true && Object.keys(weekObj).length > 0)
            ? getWeeklyScore(weekObj, scoring, customScoring)
            : 0
            return weeklyScore ? total + Number(weeklyScore) : total;
        }, 0);
    };
    
    const sort = (x, y, sortCol, scoring) => {
        let xTotal, yTotal;
    
        switch (sortCol[0]) {
            case 'Total':
                xTotal = getTotalScore(x, scoring);
                yTotal = getTotalScore(y, scoring);
                break;
            case 'Avg':
                xTotal = getAverageScore(x, scoring);
                yTotal = getAverageScore(y, scoring);
                break;
            default:
                xTotal = getCustomScore(x, sortCol, scoring);
                yTotal = getCustomScore(y, sortCol, scoring);
        }
    
        return yTotal - xTotal || (xTotal ? -1 : (yTotal ? 1 : 0));
    };


    const getRow = (row, i, posFilter, sortColumn, scoring, teamFilter, rowSelect, useCompare, colorGenerator) => {
        const temp = i.rk;

        // Only increment the rank when the player fits the filter dropdowns
        // Selected players always appear, but the should not have a rank nor take up a rank if they don't match the filters
        var filterMatch = false;
        if (posFilterLogic(posFilter, row.position.toLowerCase())) {
            i.rk++;
            filterMatch = true;
        }


        return (
                <Row key={i} row={ row } rk={ temp } scoring={ scoring } 
                    sortColumn={ sortColumn } teamFilter={ teamFilter } 
                    selectedRows={ rowSelect } useCompare={ useCompare }
                    filterMatch = { filterMatch } week = { currentWeek }
                    colorGenerator = { colorGenerator } allowRowSelect = {allowRowSelect}
                />
        );
        
    }


    const getTable = (gameLog, colorGenerator) => {
        if (Object.keys(gameLog).length > 0) {
                return gameLog
                    .filter((row) => {
                        return filter(row, posFilter, rowSelect);
                    })
                    .sort((x, y) => {
                        return sort(x, y, sortColumn, scoring);
                    })
                    .map((row) => (
                        getRow(row, i, posFilter, sortColumn, scoring, teamFilter, rowSelect, useCompare, colorGenerator)
                    ))
        }
    }


    const getColorGenerator = (gameLog, posFilter, scoring) => {
        let flatData = gameLog.filter(player => {
                                return posFilterLogic(posFilter, player.position.toLowerCase())
                            })
                            .map(player => {               
                                return player.weeks
                                    .filter(weekObj => Object.keys(weekObj).length > 0 && !weekObj.hasOwnProperty('bye'))
                                    .map(weekObj => {
                                        return Math.round(getWeeklyScore(weekObj, scoring, customScoring))
                                    })
                            })
                            .flat(1)
                            .filter(points => points > 0)
                            .sort((a,b) => a-b)


        for (let i=1; i>-11; i--) {
            flatData.unshift(i);
        }

        let small   = flatData[Math.round(flatData.length*.10)]
        let big     = flatData[Math.round(flatData.length*.98)-1]
        
        var myRainbow = new Rainbow();
        myRainbow.setNumberRange(small-1, big)

        return flatData.reduce((obj, val) => ( { ...obj, [val]: '#' + myRainbow.colourAt(val) } ), {})
    }


    var i = { rk: 1 };  // This is for creating the rank while iterating in the map

    const posFilter = useSelector((state) => state.posFilter.posFilter);
    const sortColumn = useSelector((state) => state.sortCol.sortCol);
    const scoring = useSelector((state) => state.scoring.scoringType);
    const teamFilter = useSelector((state) => state.teamFilter.teamFilter);
    const rowSelect = useSelector((state) => state.rowSelect.rowSelect);
    const useCompare = useSelector((state) => state.useCompare.useCompare);

    const colorGenerator = getColorGenerator(gameLog, posFilter, scoring)

    if (colorGeneratorRedux === '') {
        dispatch(setColorGenerator(colorGenerator))
    }

    return (
        <tbody>

            {
                getTable(gameLog, colorGenerator)
            } 
        </tbody>
    );
}

export default Body;