import React from 'react';
import Dropdown from 'react-dropdown';
import { useSelector, useDispatch } from 'react-redux';
import './stylesheets/PositionDropdown.css'
import { setYear } from '../../redux/ducks/year';
import { setTable } from '../../redux/ducks/table';
import { setDidLoad } from '../../redux/ducks/loading';
import axios from 'axios';
import { baseUrl, isProd } from '../../App';




export const currentYear = '2023'


const YearDropdown = () => {
    const dispatch = useDispatch();


    // Array of json from 2023 - 2002
    const options = Array.from({ length: 2023 - 2002 + 1 }, (_, i) => 2023 - i)
        .map(year => ({ value: year.toString(), label: year.toString() }));

    const year = useSelector((state) => state.year.year);

    const handleChange = (selected) => {
        dispatch(setYear(selected.value));
        dispatch(setDidLoad(false));

        var url = '';
        if (isProd()) {
            url = baseUrl + `/api/gameLog?year=${selected.value}`
        } else {
            url = `http://localhost:3000/api/gameLog?year=${selected.value}`
        }

        axios.request({
            method: 'get',
            url: url
          }).then(resp => {
              dispatch(setTable(resp.data));
              dispatch(setDidLoad(true));
          }).catch(e => {
              dispatch(setTable({}));
              dispatch(setDidLoad(true));
          });
    }
    

    const defaultOption = {
        value: year,
        label: year
    }

    return (
        <div className="dropdownContainer">
            <Dropdown options={options} onChange={(e) => handleChange(e)} value={defaultOption} />
        </div>
    )
      
      
}

export default YearDropdown;