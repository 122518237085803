import React, { useState } from 'react';
import './stylesheets/CreateAccount.css'
import axios from 'axios';
import { baseUrl } from '../../App';
import EmailSent from './EmailSent';

const CreateAccount = (props) => {
    // We pass state variable showModal from the parent (TopBar)
    const { showModalLogin, showModalSignUp } = props;

    const [success, setSuccess] = useState();
    const [errors, setErrors] = useState({});

    const [form, setForm] = useState();

    const submitCreateAccount = (e) => {
        e.preventDefault();
        axios.request({
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            url: baseUrl + '/api/user/new',
            data: Object.assign({}, form, {baseUrl: baseUrl})
        }).then(resp => {
            if (resp.data.errors) {
                setErrors(resp.data.errors);
            } else {
                setSuccess(true);
            }
            
        }).catch(e => {
            
        });
    }

    const signIn = () => {
        showModalSignUp(false);
        showModalLogin(true);
    }

    return (
        <div>
            {!success ? (
            <div class="login">
                <div class="signupTitle">
                    <label > Create Account </label>
                </div>
                <form>
                    <div class="emailLabel formLabel">
                        <label> Email </label>
                    </div>

                    {
                        // Add errors messages on bad email
                        errors.username ? (
                            <div>
                                <label className="errorText"> { errors.username } </label>
                            </div>
                        ) : null
                    }

                    <div class="emailInput">
                        <input type="email" onChange={e => setForm({...form, username: e.target.value})} />
                    </div>

                    <div class="firstNameLabel formLabel">
                        <label> First Name </label>
                    </div>

                    {
                        // Add errors messages on bad first name
                        errors.firstName ? (
                            <div>
                                <label className="errorText"> { errors.firstName } </label>
                            </div>
                        ) : null
                    }

                    <div class="firstNameInput">
                        <input onChange={e => setForm({...form, firstName: e.target.value})} />
                    </div>

                    <div class="lastNameLabel  formLabel">
                        <label> Last Name </label>
                    </div>

                    {
                        // Add errors messages on bad last name
                        errors.lastName ? (
                            <div>
                                <label className="errorText"> { errors.lastName } </label>
                            </div>
                        ) : null
                    }

                    <div class="lastNameInput">
                        <input onChange={e => setForm({...form, lastName: e.target.value})} />
                    </div>

                    <div class="passwordLabel  formLabel">
                        <label> Password </label>
                    </div>

                    {
                        // Add errors messages on bad password
                        errors.password ? (
                            <div>
                                <label className="errorText"> { errors.password } </label>
                            </div>
                        ) : null
                    }

                    <div class="passwordInput">
                        <input type="password" onChange={e =>  setForm({...form, password: e.target.value})} />
                    </div>

                    <div class="confirmLabel  formLabel">
                        <label> Confirm Password </label>
                    </div>

                    {
                        // Add errors messages on bad passwordMatch
                        errors.passwordMatch ? (
                            <div>
                                <label className="errorText"> { errors.passwordMatch } </label>
                            </div>
                        ) : null
                    }

                    <div class="confirmInput">
                        <input type="password" onChange={e =>  setForm({...form, passwordMatch: e.target.value})} />
                    </div>
                    
                    <div> 
                        <button onClick={submitCreateAccount}> Submit </button>
                    </div>

                    <div class="createAccountMsg" onClick={signIn}>
                        <label > Have an account already? Sign-in </label>
                    </div>
                </form>
            </div>
            ) : ( 
                <EmailSent />
            )}
        </div>
    );
}

export default CreateAccount;