const CUSTOM_SCORING = 'customScoring';

export const setCustomScoring = (custom) => ({
    type: CUSTOM_SCORING,
    customScoring: custom
})

const initialState = {
    customScoring: {
        numerator: [],
        denominator: []
    }
}


const getCustomScoring = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOM_SCORING:
            return {...state, customScoring: action.customScoring};
        default:
            return state;
    }
}

export default getCustomScoring;