const POS_FILTER = 'posFilter';

export const posFilter = (position) => ({
    type: POS_FILTER,
    position: position
})

const initialState = {
    posFilter: 'flex'
}

const filter = (state = initialState, action) => {
    switch (action.type) {
        case POS_FILTER:
            return {...state, posFilter: action.position.value};
        default:
            return state;
    }
}

export default filter;