const TABLE = 'table';                        // Define the data type 

// This is our setter
// dispatch(isAuthenticated(true));
export const setTable = (table) => ({             // Define our action
    type: TABLE,                                     // Data type and value(authenicated)
    table: table                                        
})

// Define data format
const initialState = {                                      // Define initialState
    table: {}
}

// Define reducer
// This part will be used to retrieve the state. 
// const table = useSelector((state) => state.table.table);
const table = (state = initialState, action) => {
    switch(action.type) {
        case TABLE:
            return {...state, table: action.table};
        default:
            return state;
    }
}      

export default table;