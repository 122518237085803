const SORT_COL = 'sortCol';

export const sortCol = (column) => ({
    type: SORT_COL,
    column: column
});

const initialState = {
    sortCol: ['Total']
}

const sort = (state = initialState, action) => {
    switch (action.type) {
        case SORT_COL:
            return getSort(state, action.column);
        default: 
            return state;
    }
}


const getSort = (state, column) => {
    if (column === '') {
        return {...state, sortCol: []}
    }

    var columns = state.sortCol;

    const index = columns.indexOf(column);

    if (column === 'Total' || column === 'Avg') {
        columns = [column];
    } else {
        const totalIndex = columns.indexOf('Total');
        const avgIndex = columns.indexOf('Avg');

        if (totalIndex > -1 || avgIndex > -1) {
            columns = [];
        }

        if (index > -1) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
    }

    if (columns.length === 0) {
        columns.push('Total');
    }
    
    return {...state, sortCol: [...columns]};
}

export default sort;