import { combineReducers, createStore } from 'redux';

import posFilterReducer from './ducks/filter';
import sortColReducer from './ducks/sort';
import scoringReducer from './ducks/scoring';
import teamFilterReducer from './ducks/team';
import rowSelectReducer from './ducks/rowSelect';
import compareRowReducer from './ducks/compare';
import authenticateReducer from './ducks/authenticate';
import yearReducer from './ducks/year';
import tableReducer from './ducks/table';
import didLoadReducer from './ducks/loading';
import summaryReducer from './ducks/summary';
import customScoringReducer from './ducks/customScoring';
import summaryGeneratorReducer from './ducks/colorGenerator';


const reducer = combineReducers({
    posFilter: posFilterReducer,
    sortCol: sortColReducer,
    scoring: scoringReducer,
    teamFilter: teamFilterReducer,
    rowSelect: rowSelectReducer,
    useCompare: compareRowReducer,
    isAuthenticated: authenticateReducer,
    year: yearReducer,
    table: tableReducer,
    didLoad: didLoadReducer,
    summary: summaryReducer,
    customScoring: customScoringReducer,
    colorGenerator: summaryGeneratorReducer,
});

const store = createStore(reducer);

export default store;