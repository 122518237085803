import React, { useState } from 'react';
import './stylesheets/CreateAccount.css';
import axios from 'axios';
import { baseUrl } from '../../App';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const ForgotPassword = (props) => {
    const { showModalLogin, showModalForgotPassword } = props;

    const [form, setForm] = useState({
        username: ''
    });

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState();

    const submitRequestResetPassword = (e) => {
        e.preventDefault();
        axios.request({
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            url: baseUrl + '/api/user/forgot',
            data: Object.assign({}, form, {baseUrl: baseUrl})
        }).then(resp => {
            if (resp.data.errors) {
                setErrors(resp.data.errors);
            } else {
                setSuccess(true);
            }
            
        }).catch();
    }

    const signUp = () => {
        showModalForgotPassword(false);
        showModalLogin(true);
    }


    return (
        <div>

            {!success ? (
                <div class="login">
                    <div class="signupTitle">
                        <label > Forgot Password </label>
                    </div>
                    <form>
                        <div class="emailLabel formLabel">
                            <label> Email </label>
                        </div>

                        {
                            // Add errors messages on bad email
                            errors.username ? (
                                <div>
                                    <label className="errorText"> { errors.username } </label>
                                </div>
                            ) : null
                        }

                        <div class="emailInput">
                            <input type="email" onChange={e => setForm({...form, username: e.target.value})} />
                        </div>
                        
                        <div> 
                            <button onClick={submitRequestResetPassword}> Submit </button>
                        </div>

                        <div class="createAccountMsg" onClick={signUp}>
                            <label > &larr; Go back to login </label>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="login">
                    <div>
                        Check your email to reset your password
                    </div>
                    <div style={{textAlign: 'center', marginTop: '40px'}}>
                        <DoneAllIcon style={{ color: 'green', height: '40px', width: '40px'}}/>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ForgotPassword;