const DID_LOAD = 'didLoad';                        // Define the data type 

// This is our setter
// dispatch(setYear(2021));
export const setDidLoad = (didLoad) => ({      // Define our action
    type: DID_LOAD,                                     // Data type and value(loading)
    didLoad: didLoad                                        
})

// Define data format
const initialState = {                                      // Define initialState
    didLoad: false
}

// Define reducer
// This part will be used to retrieve the state. 
// const year = useSelector((state) => state.loading.loading);
const didLoad = (state = initialState, action) => {
    switch(action.type) {
        case DID_LOAD:
            return {...state, didLoad: action.didLoad};
        default:
            return state;
    }
}      

export default didLoad;