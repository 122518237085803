import React, { useState, useEffect } from 'react';
import { baseUrl, getPosition } from '../../App';
import axios from 'axios';
import TopBar from './TopBar';

const AccountVerification = () => {

    const [errors, setErrors] = useState();

    let verificationCode = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

    useEffect(() => {
        axios.request({
            method: 'get',
            url: baseUrl + '/api/user/verify/' + verificationCode,
        }).then(resp => {
            if (resp.data.errors) {
                setErrors(resp.data.errors);
            }
        });
    }, []);

    return (
        <div>
            <div className="page">
                <div className="verify">
                    {
                        errors ? (
                            <div> 
                                <div> { errors.verificationCode }</div>
                                <div>
                                    <a href={ baseUrl }> Return to stats4fantasy.com and Login to get a new Verification Code </a>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div> Your account is verified! </div>
                                <div>
                                    <a href={ baseUrl }> Return to stats4fantasy.com and Log In! </a>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default AccountVerification;