import React from 'react';
import Dropdown from 'react-dropdown';
import { useDispatch } from 'react-redux';
import './stylesheets/PositionDropdown.css'
import { teamFilter } from '../../redux/ducks/team';

const TeamDropdown = () => {
    const dispatch = useDispatch();
    const options = [
        {
            label: 'TEAM',
            value: 'team'
        },
        {
            label: 'ARI',
            value: 'ari'
        },
        {
            label: 'ATL',
            value: 'atl'
        },
        {
            label: 'BAL',
            value: 'bal'
        },
        {
            label: 'BUF',
            value: 'buf'
        },
        {
            label: 'CAR',
            value: 'car'
        },
        {
            label: 'CHI',
            value: 'chi'
        },
        {
            label: 'CIN',
            value: 'cin'
        },
        {
            label: 'CLE',
            value: 'cle'
        },
        {
            label: 'DAL',
            value: 'dal'
        },
        {
            label: 'DEN',
            value: 'den'
        },  
        {
            label: 'DET',
            value: 'det'
        }, 
        {
            label: 'GB',
            value: 'gb'
        },
        {
            label: 'HOU',
            value: 'hou'
        },
        {
            label: 'IND',
            value: 'ind'
        },
        {
            label: 'JAX',
            value: 'jax'
        },
        {
            label: 'KC',
            value: 'kc'
        },
        {
            label: 'LAC',
            value: 'lac'
        },
        {
            label: 'LAR',
            value: 'lar'
        },
        {
            label: 'LV',
            value: 'lv'
        },
        {
            label: 'MIA',
            value: 'mia'
        },
        {
            label: 'MIN',
            value: 'min'
        },
        {
            label: 'NE',
            value: 'ne'
        },  
        {
            label: 'NO',
            value: 'no'
        },
        {
            label: 'NYG',
            value: 'nyg'
        },
        {
            label: 'NYJ',
            value: 'nyj'
        },
        {
            label: 'PHI',
            value: 'phi'
        },
        {
            label: 'PIT',
            value: 'pit'
        },
        {
            label: 'SEA',
            value: 'sea'
        }, 
        {
            label: 'SF',
            value: 'sf'
        },
        {
            label: 'TB',
            value: 'tb'
        },
        {
            label: 'TEN',
            value: 'ten'
        },        
        {
            label: 'WAS',
            value: 'was'
        }
    ];


    const handleChange = (selected) => {
        dispatch(teamFilter(selected));
    }

    const defaultOption = options[0];

    return (
        <div className="dropdownContainer">
            <Dropdown options={options} onChange={(e) => handleChange(e)} value={defaultOption} />
        </div>
    )
      
      
}

export default TeamDropdown;